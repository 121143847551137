import React, { useState } from "react";
import { Button, Col, Form, Spinner } from "react-bootstrap";
import { Formik, FormikValues } from "formik";
import * as yup from "yup";

import dayjs from "dayjs";

import advancedFormat from "dayjs/plugin/advancedFormat";
import { checkAuthCode } from "src/api/user";

dayjs.extend(advancedFormat);

interface Props {
    onValidCode: (code: string) => void;
}

const ERROR_FEEDBACK = "You must enter a valid RSVP code, which you can find on your invite.";

const schema = yup.object().shape({
    code: yup.string().required(ERROR_FEEDBACK),
});

export const RsvpCode: React.FC<Props> = ({ onValidCode }) => {
    const [isInvalidAttempt, setInvalidAttempt] = useState(false);

    const handleSubmit = async (values: FormikValues) => {
        setInvalidAttempt(false);
        const code = values["code"];
        if (code) {
            let response;
            try {
                response = await checkAuthCode(code);
            } catch (err) {
                console.error(err);
                setInvalidAttempt(true);
            }
            if (response) {
                const { codeValid } = response.data;

                if (codeValid) {
                    onValidCode(code);
                } else {
                    setInvalidAttempt(true);
                }
            }
        }
    };

    return (
        <>
            <div className="align-middle">
                <h3 id="rsvp-label" data-test="main-heading" className="text-white text-center">
                    Please enter the code on your invite to RSVP
                </h3>
                <Formik
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                    initialValues={{
                        code: "",
                    }}
                >
                    {({ handleSubmit, handleChange, values, touched, isValid, errors, isSubmitting }) => (
                        <Form noValidate onSubmit={handleSubmit} className="fat-form">
                            <Col className="align-items-center">
                                <Form.Group controlId="validationFormik01">
                                    <Form.Control
                                        type="text"
                                        name="code"
                                        value={values.code}
                                        onChange={handleChange}
                                        aria-labelledby="rsvp-label"
                                        size="lg"
                                        className="text-center form-control-xl text-uppercase"
                                        autoFocus
                                        isInvalid={touched.code && !!errors.code}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        <div className="text-white">{errors.code}</div>
                                    </Form.Control.Feedback>
                                    <Button type="submit" disabled={!isValid || isSubmitting} className="w-100">
                                        {isSubmitting ? (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                <span className="visually-hidden">Loading...</span>
                                            </>
                                        ) : (
                                            <>Check my code!</>
                                        )}
                                    </Button>
                                    {isInvalidAttempt && <p className="text-white">{ERROR_FEEDBACK}</p>}
                                </Form.Group>
                            </Col>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};
