import React from "react";
import { Col, Container, Row, Button, ButtonGroup } from "react-bootstrap";
import { useSession } from "next-auth/react";
import { externalRoutes, routes } from "@constants/routes";
import Link from "next/link";

export const Footer: React.FC = () => {
    const { data: session } = useSession();

    return (
        <footer>
            <Container>
                <Row>
                    <Col lg={4}>
                        <nav className="text-center" aria-label="Footer">
                            <ButtonGroup vertical>
                                <Link passHref href={routes.rsvp.index}>
                                    <Button variant="link">RSVP</Button>
                                </Link>
                                {session?.userIsValid && (
                                    <>
                                        <Link passHref href={routes.travel}>
                                            <Button variant="link">Travel</Button>
                                        </Link>
                                        <Link passHref href={routes.schedule}>
                                            <Button variant="link">Schedule</Button>
                                        </Link>
                                        <Link passHref href={routes.photos}>
                                            <Button variant="link">Upload Photos</Button>
                                        </Link>
                                        <Link passHref href={routes.questions}>
                                            <Button variant="link">Q&A</Button>
                                        </Link>
                                        <Link passHref href={routes.gifts}>
                                            <Button variant="link">Gifts</Button>
                                        </Link>
                                    </>
                                )}
                            </ButtonGroup>
                        </nav>
                    </Col>
                    <Col lg={4}>
                        <div className="w-100 text-center py-4">
                            <div className="">Made with ❤️ by Dom & Sarah</div>
                            <nav aria-label="Terms and Policies">
                                <ButtonGroup vertical className="pt-4">
                                    <Link passHref href={routes.privacyPolicy}>
                                        <Button variant="link">Privacy Policy</Button>
                                    </Link>
                                    <Link passHref href={routes.terms}>
                                        <Button variant="link">T&Cs</Button>
                                    </Link>
                                </ButtonGroup>
                            </nav>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="text-center py-4">
                            <Button href={externalRoutes.contactUs}>Get in touch with us</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};
