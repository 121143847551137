import React from "react";
import { Container, Button } from "react-bootstrap";
import Countdown from "react-countdown";

import { signIn, useSession } from "next-auth/react";
import useSWR from "swr";
import { nextApiRoutes, routes } from "@constants/routes";
import { getWeddingInfo } from "src/api/next/weddingInfo";
import Link from "next/link";

export const Main: React.FC = () => {
    const { data: session } = useSession();

    const isLoggedIn = !!session;

    const { data: weddingInfo } = useSWR(nextApiRoutes.weddingInfo, getWeddingInfo);

    const { weddingDate, weddingDateFull, weddingTime } = weddingInfo || {};
    return (
        <>
            <Container className="d-flex justify-content-center align-items-center">
                <main className="text-center">
                    <h1 className="title">Sarah &amp; Dom</h1>
                    <h2 className="intro mx-auto">are getting married and we'd love for you to join us!</h2>
                    {weddingDate && (
                        <div className="">
                            <h2 className=" mx-auto mt-5 mb-3">
                                {weddingDateFull} {weddingTime}
                            </h2>
                            {weddingDate && (
                                <Countdown
                                    date={weddingDate.toDate()}
                                    intervalDelay={0}
                                    precision={3}
                                    renderer={({ days, hours, minutes }) => (
                                        <div suppressHydrationWarning>
                                            {days} days, {hours} hours, {minutes} minutes to go!
                                        </div>
                                    )}
                                />
                            )}
                        </div>
                    )}
                    <>
                        <Link passHref href={routes.rsvp.index}>
                            <Button className="mt-5 rsvp on-dark">RSVP</Button>
                        </Link>

                        {!isLoggedIn && (
                            <p className="mt-2">
                                or{" "}
                                <Button variant={"link"} onClick={() => signIn("cognito")}>
                                    sign in
                                </Button>{" "}
                                if you have an account
                            </p>
                        )}
                    </>
                </main>
            </Container>
        </>
    );
};

export default Main;
