import { Header, Footer } from "@components";
import Head from "next/head";

import React, { PropsWithChildren } from "react";
interface Props {
    className?: string;
    paddedNav?: boolean;
    title?: string;
}

export const AppLayout: React.FC<PropsWithChildren<Props>> = ({ children, className, paddedNav, title }) => {
    return (
        <>
            <Head>
                <title>{title && `${title} - `}Sarah & Dom</title>
            </Head>
            <div className={`${className} app-layout`}>
                <Header padded={paddedNav} />
                {children}
                <Footer />
            </div>
        </>
    );
};
