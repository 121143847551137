import { AxiosResponse } from "axios";
import { apiRoutes } from "@constants/routes";
import { api } from "./api";
import { GetGuests, GetHasValidatedCodeResponse } from "@libs/types/user";

export const checkAuthCode = async (code: string): Promise<AxiosResponse> => {
    return await api.post(
        apiRoutes.user.validateCode,
        { code },
        {
            headers: {
                "Content-Type": "application/json",
            },
            validateStatus: (status) => {
                return (status >= 200 && status < 300) || status == 403;
            },
        },
    );
};

export const getHasValidatedCode = async (token?: string): Promise<AxiosResponse<GetHasValidatedCodeResponse>> => {
    let headers: Record<string, string> = {};

    if (token) {
        headers = {
            Authorization: `Bearer ${token}`,
        };
    }

    return await api.get(apiRoutes.user.hasValidatedCode, { headers });
};

export const getUserGuestList = async (): Promise<AxiosResponse<GetGuests>> => {
    return await api.get(apiRoutes.user.getUserGuestList, {
        headers: {
            "Content-Type": "application/json",
        },
    });
};

export const submitGuestRsvp = async (): Promise<AxiosResponse> => {
    return await api.post(
        apiRoutes.user.submitGuestRsvp,
        {},
        {
            headers: {
                "Content-Type": "application/json",
            },
        },
    );
};
