import { nextApiRoutes } from "@constants/routes";
import { api } from "../api";
import dayjs, { Dayjs } from "dayjs";

export interface WeddingInfo {
    venueAddress?: string;
    venueName?: string;
    weddingDate?: Dayjs;
    weddingTime?: string;
    weddingDateFull?: string;
}

export const getWeddingInfo = async (): Promise<WeddingInfo> => {
    const { venueAddress, venueName, weddingDateIso } = await api
        .get(nextApiRoutes.weddingInfo)
        .then((res) => res.data);

    if (weddingDateIso) {
        const weddingDate = dayjs(weddingDateIso);

        return {
            venueAddress,
            venueName,
            weddingDate,
            weddingTime: weddingDate.format("h:mma"),
            weddingDateFull: weddingDate.format("dddd Do MMMM YYYY"),
        };
    }

    return {};
};
