import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import ellipsize from "ellipsize";
import { Session } from "next-auth";
import { signIn, signOut } from "next-auth/react";
import { routes } from "@constants/routes";
import { LoadingSpinner } from "@components/LoadingSpinner";
import { isAdmin } from "@utils/auth/authenticate";

interface Props {
    session: null | Session;
    loading: boolean;
    signIn: typeof signIn;
    signOut: typeof signOut;
}

const MAX_NAME_LENGTH = 25;

const getFriendlyName = (session: Session): string | undefined => {
    const { user, profile } = session;
    if (profile) {
        let friendlyName = profile.givenName || profile.nickname;
        if (friendlyName) {
            friendlyName = ellipsize(friendlyName, MAX_NAME_LENGTH);
            return `Hi ${friendlyName}`;
        }
    }
    if (user?.email) {
        return ellipsize(user?.email, MAX_NAME_LENGTH);
    }
    return undefined;
};

interface SignedInProps {
    session: Session;
}

const AccountDropdown: React.FC<SignedInProps> = ({ session }) => {
    const friendlyName = getFriendlyName(session);

    return (
        <NavDropdown title={<span aria-label="Account Menu">{friendlyName}</span>} align="end">
            <NavDropdown.Item disabled>
                <div style={{ overflow: "hidden", whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
                    {session.profile?.givenName} {session.profile?.familyName}
                </div>
                <div className="text-lighter">{session.user?.email}</div>
            </NavDropdown.Item>
            <NavDropdown.Divider />

            <NavDropdown.Item href={routes.account}>My Account</NavDropdown.Item>
            {isAdmin(session) && <NavDropdown.Item href={routes.admin.index}>Admin</NavDropdown.Item>}
            <NavDropdown.Item onClick={() => signOut()}>Sign out</NavDropdown.Item>
        </NavDropdown>
    );
};

export const LoginNav: React.FC<Props> = ({ session, loading }) => {
    return (
        <div className="login">
            {loading && <LoadingSpinner />}
            {session ? (
                <AccountDropdown session={session} />
            ) : (
                <div className="">
                    <Nav.Link onClick={() => signIn("cognito")}>Sign in</Nav.Link>
                </div>
            )}
        </div>
    );
};
