// import publicConfig from "@utils/publicConfig";
import getConfig from "next/config";

const {
    publicRuntimeConfig: { API_ROUTE_GUEST, API_ROUTE_USER, API_ROUTE_INVITE, API_ROUTE_NEXT, BASE_URL },
} = getConfig();

export const baseUrl = BASE_URL;

export const routes = {
    admin: {
        index: "/admin",
        invites: "/admin/invites",
        users: "/admin/users",
        guests: "/admin/guests",
    },
    createAccount: "/create-account",
    gifts: "/gifts",
    home: "/",
    photos: "/photos",
    playlist: "/playlist",
    questions: "/qa",
    rsvp: {
        index: "/rsvp",
        response: "/rsvp/response",
        responseEdit: "/rsvp/response?edit=true",
        guest: (id: string): string => `/rsvp/guest/${id}`,
    },
    schedule: "/schedule",
    travel: "/travel",
    userHome: "/home",
    account: "/account",
    terms: "/terms",
    privacyPolicy: "/privacy-policy",
    signIn: "/signin",
};

export const apiRoutes = {
    user: {
        validateCode: `${API_ROUTE_USER}/user/code/validate`,
        hasValidatedCode: `${API_ROUTE_USER}/user/code/validate`,
        getUserGuestList: `${API_ROUTE_USER}/user/guests`,
        submitGuestRsvp: `${API_ROUTE_USER}/user/rsvp`,
    },
    guests: {
        get: (id: string): string => `${API_ROUTE_GUEST}/guests/${id}`,
        rsvp: (id: string): string => `${API_ROUTE_GUEST}/guests/${id}/rsvp`,
        update: (id: string): string => `${API_ROUTE_GUEST}/guests/${id}`,
        list: `${API_ROUTE_GUEST}/guests`,
    },
    admin: {
        guests: {
            list: `${API_ROUTE_GUEST}/admin/guests`,
        },
        invites: {
            list: `${API_ROUTE_INVITE}/admin/invites`,
            get: (id: string): string => `${API_ROUTE_INVITE}/admin/invites/${id}`,
            create: `${API_ROUTE_INVITE}/admin/invites`,
            update: (id: string): string => `${API_ROUTE_INVITE}/admin/invites/${id}`,
            delete: (id: string): string => `${API_ROUTE_INVITE}/admin/invites/${id}`,
        },
        users: {
            list: `${API_ROUTE_USER}/admin/users`,
        },
    },
};

export const nextApiRoutes = {
    auth: {
        signup: `${API_ROUTE_NEXT}/auth/signup`,
    },
    invite: {
        generateCode: `${API_ROUTE_NEXT}/generate/invitecode`,
    },
    weddingInfo: `${API_ROUTE_NEXT}/wedding`,
};

export const externalRoutes = {
    paypalProfile: "https://paypal.me/Dwills01?country.x=GB&locale.x=en_GB",
    contactUs: `mailto:"Sarah & Dom"<hello@sarahdom.com>`,
};
