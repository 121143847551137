import React, { useContext } from "react";

import { LoginNav } from "@components";
import { Container, Nav, Navbar } from "react-bootstrap";
import { signIn, signOut, useSession } from "next-auth/react";
import { routes } from "@constants/routes";
import { TokenContext } from "@stores/TokenProvider";

interface Props {
    fixedNav?: boolean;
    padded?: boolean;
}

export const Header: React.FC<Props> = ({ fixedNav, padded }) => {
    const { userContext } = useContext(TokenContext);
    const { data: session, status } = useSession();
    const isLoading = status === "loading";

    const handleToggle = () => {
        window.scrollTo(0, 0);
    };

    return (
        <header>
            <Navbar
                variant="dark"
                id="header-nav"
                expand="lg"
                fixed={fixedNav ? "top" : undefined}
                className={`${padded ? "padded" : ""}`}
                role="navigation"
                aria-label="Main"
            >
                <Container fluid>
                    <Navbar.Toggle aria-controls="navbar-nav" onClick={handleToggle} />
                    <Navbar.Collapse id="navbar-nav">
                        <Navbar.Brand>
                            <Nav.Link href="/" aria-label="Home">
                                Sarah & Dom
                            </Nav.Link>
                        </Navbar.Brand>

                        <Nav className="me-auto ms-5">
                            <Nav.Link href={routes.rsvp.index}>RSVP</Nav.Link>
                            {/* {(session?.userIsValid || userContext.userIsValid) && ( */}
                            <>
                                <Nav.Link href={routes.travel}>Travel</Nav.Link>
                                <Nav.Link href={routes.schedule}>Schedule</Nav.Link>
                                <Nav.Link href={routes.photos}>Upload Photos</Nav.Link>
                                <Nav.Link href={routes.questions}>Q&A</Nav.Link>
                                <Nav.Link href={routes.gifts}>Gifts</Nav.Link>
                            </>
                            {/* )} */}
                        </Nav>
                        <Nav className="justify-content-end" style={{ alignSelf: "baseline" }}>
                            <LoginNav session={session} loading={isLoading} signIn={signIn} signOut={signOut} />
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};
