import { GetServerSidePropsContext, GetServerSidePropsResult } from "next";
import { Session } from "next-auth";
import { getSession } from "next-auth/react";

export const ROLE_ADMIN = "admin";

export const hasRole = (role: string, session?: Session | null | undefined) => {
    const roles = session?.roles;
    return roles && roles.includes(role);
};

export const isAdmin = (session?: Session | null | undefined) => {
    return hasRole(ROLE_ADMIN, session);
};

export const withRole = async (
    context: GetServerSidePropsContext,
    destination = "/",
    role = ROLE_ADMIN,
    fn?: (context: GetServerSidePropsContext) => object,
) => {
    const session = await getSession(context);
    const isValidRole = hasRole(role, session);

    // No authenticated session
    if (!isValidRole) {
        return {
            redirect: {
                permanent: false,
                destination,
            },
        };
    }

    // Returned by default, when `fn` is undefined
    const defaultResponse = { props: { session } };

    return fn ? { ...defaultResponse, ...fn(context) } : defaultResponse;
};

const defaultValidator: Validator = async (session) => {
    if (session) {
        const accessToken = session?.accessToken;
        if (accessToken) {
            return true;
        }
    }
    return false;
};

export interface DefaultProps {
    session: Session;
}

export type Validator = <Props extends DefaultProps = DefaultProps>(
    session?: Session | null,
) => Promise<boolean | GetServerSidePropsResult<Props>>;

export const withAuth = async (
    context: GetServerSidePropsContext,
    destinationInvalid = "/",
    validator: Validator | undefined,
    fn?: (context: GetServerSidePropsContext) => object,
) => {
    const session = await getSession(context);
    const defaultResponse = { props: { session } };

    const authValidator = validator || defaultValidator;
    const validatorResult = await authValidator(session);
    if (typeof validatorResult === "boolean") {
        if (!validatorResult) {
            return {
                redirect: {
                    permanent: false,
                    destination: destinationInvalid,
                },
            };
        }
    } else {
        return { ...defaultResponse, ...validatorResult };
    }

    // Returned by default, when `fn` is undefined

    return fn ? { ...defaultResponse, ...fn(context) } : defaultResponse;
};
