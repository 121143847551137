import React, { PropsWithChildren } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { routes } from "@constants/routes";
import { AppLayout } from "../AppLayout";

interface Props {
    className?: string;
}

export const AdminLayout: React.FC<PropsWithChildren<Props>> = ({ children, className }) => {
    return (
        <AppLayout className={`admin ${className}`} paddedNav title="Admin">
            <Navbar id="adminNav" expand="lg" bg="dark" variant="dark">
                <Container fluid>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto ms-5">
                            <Nav.Link href={routes.admin.index}>Admin</Nav.Link>
                            <Nav.Link href={routes.admin.invites}>Invites</Nav.Link>
                            <Nav.Link href={routes.admin.users}>Users</Nav.Link>
                            <Nav.Link href={routes.admin.guests}>Guests</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {children}
        </AppLayout>
    );
};
